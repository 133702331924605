import '../../../../_newcommon/js/events/zvuk';

import $ from 'jquery'
import player from 'js/modules/player/player'
import kinetic from 'js/modules/kinetic'
import reloadLazy from 'js/modules/lazyLoad'
import 'jquery-ui-sortable-npm' // 'jquery-ui-sortable-npm/jquery-ui-sortable.min'
import init from 'js/modules/ajaxPagination'
import listen from 'js/modules/listenAll'
import styles from 'styl/commons/common.styl'

import Swiper from 'swiper/bundle'
import {Navigation} from 'swiper/modules'
$(document).ready(function () {

    //Image error handler
    document.querySelectorAll(`img[loading="lazy"]`).forEach(item => {
        item.addEventListener('error', e => {
            e.target.src = e.target.dataset.src || 'error'
        }, {once: true})
        item.src = item.src
    })
    document.querySelectorAll('.swiper').forEach((item, index) => {
        item.id = `swiper-${index}`;
        new Swiper(`#${item.id}`, {
            modules: [Navigation],
            slidesPerView: "auto",
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    })

    var $search = $('#main-search');

    $('button[type="submit"]', $search).prop('disabled', false);
    $search.on('submit', function (e) {
        if (!$(`#${styles.search}`).val()){
            e.preventDefault();
        }
    });

    //Hamburger menu
    const hamburger = $(`#${styles['hamburger-icon']}`),
        hamburgerMenu = $(`#${styles.mobileMenuShow}`);

    hamburger.click(function(){
        hamburger.toggleClass(styles.active);
        hamburgerMenu.toggleClass(styles.down);
    });
    /*End Hamburger Menu*/

    var $songs = $(`.${styles.songs}`);

/*    /!* kinetik *!/
    $.each($(`.${styles.kinetic}`), function (){
        kinetic.init($(this));
    })*/

    if ($(`.${styles.shared_buttons}`).hasClass('shared_mobile')) {
        $('.ya-share2__container_size_m .ya-share2__list').css({
            'display': 'flex',
            'justify-content': 'space-between'
        });
        $('.ya-share2__container_size_m .ya-share2__icon').css({
            'width': '30px',
            'height': '30px',
            'background-size': 'unset'
        });
    }

    //listen
    let songsListen = styles.songsListen !== undefined ? `.${styles.songsListen}` : '.songsListen';
    listen($(`${songsListen} .${styles.item}`))

    player.init($songs);

    if ($('body').hasClass(styles.mobile)) {
        var trackName = $(`.${styles['player-controls']}`).children().first();

        $(`.${styles.play}, .${styles.songs} .${styles.item}, .${styles.radio} .${styles.item}`).on('click', function (e) {
            if ($.trim(e.target.className) === 'like') return;
            $(`.${styles['player-controls']}`).css('height', '125px');
            trackName.addClass(styles.mobile);
            $(`.${styles.progress}`).css('margin-top', '10px');
            $(`.${styles.footer}`).css('margin-bottom', '90px');

        });
    }

    /* radio */
    var radioClick = function(){};
    require(['js/modules/radio.js'], function (radio) {
        radioClick = radio;
    });

    /* ancor */
    $(`.${styles.anchor}`).on('click',function (e) {
        e.preventDefault();
        $('html,body').animate({scrollTop: ($($(this).attr('href')).offset().top - 70)},'fast');
        return false;
    });

    /* auth */
    require(['js/modules/auth.js']);

    /* favorite */
    require(['js/modules/favorite.js']);

    /* playlist */
    require(['js/modules/playlist.js']);

    /* sortable */
    require(['jquery-ui-sortable-npm/jquery-ui-sortable.min', 'js/modules/sortable.js']);

    /* search and add in playlist */
    require(['js/modules/search_inPlaylist.js']);


    require(['js/ad/banners.js']);
    require(['js/modules/topmenu.js']);
    // require(['js/modules/popular.js']);
    // require(['js/modules/youtubewidget.js']);
    require(['js/modules/dropdownmenu.js']);
    
    init(reloadLazy);
    /* Filter */
    /* Start globalChoice */
    require(['js/modules/globalChoice.js']);
    /* End globalChoice */
    /* End Filter */


    // collapsed menu
    var collapsed = $(`.${styles['menu-box']}.${styles.collapsed}`);

    !!collapsed && collapsed.on('click', function (e) {
        collapsed.removeClass(styles.collapsed);
        collapsed.off('click');
    });

    /* shared-button */
    $("#show-share").on("click", function () {
        $(`.${styles.shared_buttons} .${styles.support}`).slideToggle(100, function () {
            $(`#${styles.share}`).toggle();
        });
    });


    // add musify
    if (window.app.tmf_url) {

        if (!~document.cookie.indexOf("teleTwoDownload")) {

            let enable = true;

            let dls = document.querySelectorAll('a.' + (styles['dl'] || 'dl'));

            [...dls].forEach(function(dlEl){
                dlEl.addEventListener('click', function (e) {

                    if (!enable) return;

                    e.preventDefault();
                    e.stopPropagation();

                    enable = false;
                    document.cookie = "teleTwoDownload=; path=/;";
                    ym('46637889', 'reachGoal', 'trclick');

                    window.open(window.app.tmf_url, '_blank').focus();

                });
            })

        }
    }

});
